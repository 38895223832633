import React, { Component } from 'react';
import { connect } from 'react-redux';
import "jspdf-autotable";
import { userActions } from '../../_actions';


class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      pageNo: 1,
      size: 10,
    }
  }

  componentDidMount() {
    this.props.dispatch(userActions.getProfile());
    window.scrollTo(0, 0)
  }

  render() {
    let { users } = this.props;
    let { getProfile } = users
    console.log('getProfile___________________________', getProfile);

    return (

      <>



        <section className='overflow-hidden overflow-y-auto  lg:pb-0 pb-20'>
          <div className='grid grid-cols-2 gap-6 w-11/12 mx-auto'>
            <div className='bg-[#536DFE]/80 flex justify-between p-6 space-x-6 rounded-[12px]'>
              <div>
                <p className='xl:text-[22px] text-[14px] font-[700] text-[#FFFFFF]'>Deposit</p>
                <p className='text-[14px] font-[400] text-[#FFFFFFB2]'>Amount</p>
              </div>
              <div className='flex justify-end items-end pt-10'>
                {getProfile && getProfile.balance}
              </div>

            </div>
            {/* <div className='bg-[#536DFE]/80 flex justify-between p-6 space-x-6 rounded-[12px]'>
              <div>
                <p className='xl:text-[22px] text-[14px] font-[700] text-[#FFFFFF]'>Deposit</p>
                <p className='text-[14px] font-[400] text-[#FFFFFFB2]'>BNB</p>
              </div>
              <div className='flex justify-end items-end pt-10'>
                0
              </div>

            </div> */}
          </div>
        </section>





      </>

    );
  }

}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}
export default connect(mapStateToProps)(Dashboard);