
import React, { Component } from "react";
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import { MdAddCall } from "react-icons/md";
import { AiOutlineMail } from 'react-icons/ai';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Timer from 'otp-timer';

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldslogin: {
        email: "",
        password: "",
        mobNo: "",
        confirmPassword: "",
        OTP: "",
        countryCode: ""
      },
      errorslogin: {},
      otpSentRegister: false,
      showPassword: false,
      showConfirmPassword: false,
    };
    this.inputChange = this.inputChange.bind(this);
    this.registerSubmit = this.registerSubmit.bind(this);
    this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
    this.toggleConfirmPasswordVisibility = this.toggleConfirmPasswordVisibility.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(userActions.getCountryCodeList());
  }

  inputChange(e) {
    const { name, value } = e.target;
    this.setState(prevState => ({
      fieldslogin: {
        ...prevState.fieldslogin,
        [name]: value
      }
    }));
  }

  registerSubmit(e) {
    e.preventDefault();
    if (this.handleValidationLogin()) {
      const { userName, password } = this.state.fieldslogin;
      const data = {
        userName,
        password
      };
      this.props.dispatch(userActions.register(data, this.props));
    }
  }

  resendOtpSubmit = (e) => {

    if (this.handleValidationLogin()) {
      let data = {
        userName: this.state.fieldslogin.userName,
        password: this.state.fieldslogin.password
      }
      console.log("registerSubmit___registerSubmit:::", data);
      this.props.dispatch(userActions.register(data, this.props));
    }
  }

  otpSubmit = (e) => {
    e.preventDefault();
    let { token } = this.props.registeruser;
    let { OTP } = this.state.fieldslogin;
    this.props.dispatch(userActions.registerValidateOtp({
      token: token,
      otp: OTP
    }, this.props));
  }

  handleValidationLogin() {
    const { userName, password } = this.state.fieldslogin;
    const errorslogin = {};

    // Email validation
    // const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!userName || userName === "") {
      errorslogin["userName"] = "Enter a valid username.";
    }

    // Password validation
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    if (!password || password === "") {
      errorslogin["password"] = "Password must be at least 6 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character.";
    }

    this.setState({ errorslogin });
    return Object.keys(errorslogin).length === 0;
  }


  onClickMenu = (url) => {
    this.props.history.push(url)
  }


  togglePasswordVisibility() {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword,
    }));
  }

  toggleConfirmPasswordVisibility() {
    this.setState(prevState => ({
      showConfirmPassword: !prevState.showConfirmPassword,
    }));
  }

  render() {
    const { otpSentRegister, loading, users } = this.props;
    const { countryCodeList } = users
    console.log(this.props)
    const { errorslogin } = this.state;
    console.log('countryCodeList________________________________', countryCodeList)

    // let { countryCodeList } = users;

    return (
      <div>
        <LoadingOverlay
          active={loading}
          className="temp001"
          spinner
          text='Please wait...' />

        <section className="flex items-center w-full">
          <div className="bg-gradient-to-t to-[#5B4DCA] from-[#2D2664] w-full relative lg:block hidden">
            <img className="absolute top-0 left-0" src="/img/Vector.png" alt="" />
            <div className="flex flex-col justify-center items-center h-screen">
              <div>
                <img src="/img/logo.png" alt="" />
              </div>
              <div className="border-t border-[#FFFFFF4D] py-6">
                <h1 className="text-white text-[60px] font-[700] text-center">Welcome Back</h1>
                <p className="text-[#FFFFFFA1]/60 text-center text-[26px] font-[400]">To keep connect with us Please <br /> login with your personal information</p>
              </div>
            </div>
            <img className="absolute bottom-0 right-0" src="/img/Vector-1.png" alt="" />
          </div>
          <div className="bg-[#1C2745] w-full ">
            <div className="flex-col justify-center  flex w-5/6 mx-auto space-y-4 xl:w-3/6 h-screen">
              <div className="text-center">
                <h1 className="text-[45px] font-[700] text-white">Sign <span className="text-[#4F91F5]">Up!</span></h1>
                <p className="text-[22px] font-[400] text-[#FFFFFFA6]">Simply dummy text of the printing and
                  typesetting industry</p>
              </div>
              <div className="space-y-2">
                <label className="text-sm font-medium text-white">User Name</label>
                <div className="relative flex items-center">
                  <AiOutlineMail className="absolute right-3 text-gray-400" />
                  <input disabled={otpSentRegister ? true : false} className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base font-normal  placeholder-white placeholder:text-[#c1bfe9] bg-transparent text-white ${!this.state.errorslogin["userName"] ? "'border-opacity-20 border-opacity-100 border placeholder-white font-base " : "border-opacity-100 border-none ring-2 ring-red-300"}`} id="userName" name="userName" placeholder="User Name" type="userName" onChange={this.inputChange} />
                </div>
                {this.state.errorslogin["userName"] ? <div className="mt-1 font-serif text-red-500 invalid-feedback">{this.state.errorslogin["userName"]}</div> : null}
              </div>





              <div className="space-y-2">
                <label className="text-sm font-medium text-white">Password</label>
                <div className="relative flex items-center">
                  <input disabled={otpSentRegister ? true : false} className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base font-normal text-white placeholder-white placeholder:text-[#c1bfe9] bg-transparent ${!this.state.errorslogin["password"] ? "'border-opacity-20 border-opacity-100 border placeholder-white font-base " : "border-opacity-100 border-none ring-2 ring-red-300"}`} id="password" name="password" placeholder="Password" type={this.state.showPassword ? "text" : "password"} onChange={this.inputChange} onCopy={(e) => { e.preventDefault(); alert("Copying password is not allowed."); }} />
                  <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                    {this.state.showPassword ? <FaEyeSlash className="text-gray-400 cursor-pointer" onClick={this.togglePasswordVisibility} /> : <FaEye className="text-gray-400 cursor-pointer" onClick={this.togglePasswordVisibility} />}
                  </div>
                </div>
                {this.state.errorslogin["password"] ? <div className="mt-1 font-serif text-red-500 invalid-feedback">{this.state.errorslogin["password"]}</div> : null}
              </div>

              {
                otpSentRegister ?
                  <div className="">
                    <div className="">
                      <label className="text-sm font-medium text-white">Access Code</label>
                    </div>
                    <input className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base font-normal text-white placeholder-white placeholder:text-[#c1bfe9] bg-transparent  ${!this.state.errorslogin["OTP"] ? "'border-opacity-20 border-opacity-100 border placeholder-white font-base " : "border-opacity-100 border-none ring-2 ring-red-300"}`} id="OTP" name="OTP"
                      value={this.state.fieldslogin["OTP"] ? this.state.fieldslogin["OTP"] : ''}
                      onChange={this.inputChange} placeholder="" type="text"
                    />
                    {this.state.errorslogin["OTP"] ?
                      <div className="w-5/6 invalid-feedback text-red -500">
                        {this.state.errorslogin["OTP"]}
                      </div>
                      : null}
                  </div>
                  : null
              }


              {otpSentRegister ?
                <div className="flex items-center justify-between">
                  <div className="">
                    <Timer textColor={"white"} seconds={1} minutes={2} ButtonText="Resend Otp" resend={this.resendOtpSubmit} />
                  </div>
                </div> : null}

              {otpSentRegister ?
                <div className=" ">
                  <button className="w-full py-3 mx-auto text-white transition duration-150 ease-in-out rounded-[8px] cursor-pointer all-btn-color lg:font-bold focus:outline-none focus:underline" type="button" onClick={this.otpSubmit} >Access Code</button>
                </div>

                :
                <div className="">
                  <button className="w-full py-3 mx-auto text-white transition duration-150 ease-in-out rounded-[8px] cursor-pointer all-btn-color lg:font-bold focus:outline-none focus:underline" type="submit" onClick={this.registerSubmit}>Sign Up</button>
                </div>
              }
              <div className="py-6">
                <p className="text-center text-[18px] font-[400] text-[#A09DC5]">Already have a Account ?  <span onClick={() => this.onClickMenu('/login')} className="text-[#508EF3] cursor-pointer">Login Now</span></p>
              </div>
            </div>

          </div>
        </section>

      </div>
    )
  }
}

function mapStateToProps(state) {
  const { otpSentRegister, registeruser, loading } = state.authentication;
  const { users } = state;
  return {
    otpSentRegister,
    registeruser,
    users,
    loading
  };
}

export default connect(mapStateToProps)(Signup);
