
import { authHeader, history } from '../_helpers';
import { CONST } from '../_config';
import axios from 'axios';

import { store } from '../_helpers/store';
export const userService = {

    getKYCDetails,

    getTx,

    createKYC,

    login,
    loginValidateOtp,
    registerValidateOtp,
    validateLoginOtp,
    forgotPassword,

    updatePasswordValidateOtp,
    register,
    logout,

    getUserDetails,


    forgotUpdatePassword,

    uploadImage,




    updatePassword,
    updateUserInfo,



    saveUserInfo,

    saveKycDoc,
    uploadImagePan,
    uploadImagePassport,
    uploadImageAdhaar,
    uploadImageSelfie,
    saveKycDocAadhar,
    saveKycDocPan,
    saveKycDocPassport,
    saveKycDocSelfie,
    getWalletList,
    getProfile,
    getAddressList,
    createWallet,
    sendCoin,
    sendCoinOtpVerification,
    getUserWalletToBuy,
    getUserInfo,
    addUserTokenBalanceInWallet,
    buyGCN,
    addContractAddress,
    updateProfile,
    getCountryCodeList
};


function sendCoinOtpVerification(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/sendCoinOtpVerification`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                sendCoinOtpVerification: user
            }
            return userObj;
        });
}
function sendCoin(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/sendCoin`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                sendCoin: user
            }
            return userObj;
        });
}


// function sendCoinOtpVerification(data) {
//     console.log("datadatadatadatadata", data);
//     const options = {
//         url: CONST.BACKEND_URL + `/sendCoinOtpVerification`,
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//             "Authorization": authHeader().Authorization
//         },
//         data: JSON.stringify(data)
//     };
//     return axios(options)
//         .then(handleResponse)
//         .then(response => {
//             console.log('SEND COIN API IN SERVICE', response);
//             let bucketObj = {
//                 sendCoinOtpVerification: response.data
//             }
//             return bucketObj;
//         })
//         .catch(error => {
//             console.log('ERRORRRRRRR  =>', error);
//         })
// }

// function sendCoin(data) {
//     console.log("datadatadatadatadata", data);
//     const options = {
//         url: CONST.BACKEND_URL + `/sendCoin`,
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//             "Authorization": authHeader().Authorization
//         },
//         data: JSON.stringify(data)
//     };
//     return axios(options)
//         .then(handleResponse)
//         .then(response => {
//             console.log('SEND COIN API IN SERVICE', response);
//             let bucketObj = {
//                 sendCoin: response.data
//             }
//             return bucketObj;
//         })
//         .catch(error => {
//             console.log('ERRORRRRRRR  =>', error);
//         })
// }

function getUserWalletToBuy(data) {





    console.log("@!@!#!#!@#!", data);
    let { users } = store.getState()



    const options = {
        url: CONST.BACKEND_URL + `/getUserWalletToBuy`,
        method: 'POST',
        headers: {

            'Content-Type': 'application/json',
            "Authorization": authHeader().Authorization
        },
        data: JSON.stringify(data)

    };
    return axios(options)
        .then(users => {
            console.log("@!@!#users!", users.data);

            let bucketObj = {
                getUserWalletToBuy: users.data.data
            }
            return bucketObj;
        });
}
function addUserTokenBalanceInWallet() {





    let { users } = store.getState()



    const options = {
        url: CONST.BACKEND_URL + `/addUserTokenBalanceInWallet`,
        method: 'POST',
        headers: {

            'Content-Type': 'application/json',
            "Authorization": authHeader().Authorization
        },
        // data: JSON.stringify(data)

    };
    return axios(options)
        .then(users => {
            console.log("@!@!#users!", users.data);

            let bucketObj = {
                addUserTokenBalanceInWallet: users.data.data
            }
            // return bucketObj;
        });
}
function getUserInfo(data) {





    console.log("@!@!#!#!@#!", data);
    let { users } = store.getState()



    const options = {
        url: CONST.BACKEND_URL + `/getUserInfo`,
        method: 'POST',
        headers: {

            'Content-Type': 'application/json',
            "Authorization": authHeader().Authorization
        },
        data: JSON.stringify(data)

    };
    return axios(options)
        .then(users => {
            console.log("@!@!#users!", users.data);

            let bucketObj = {
                getUserInfo: users.data.data
            }
            return bucketObj;
        });
}

function buyGCN(data) {


    let { users } = store.getState()



    const options = {
        url: CONST.BACKEND_URL + `/buyGCN`,
        method: 'POST',
        headers: {

            'Content-Type': 'application/json',
            "Authorization": authHeader().Authorization
        },
        data: JSON.stringify(data)

    };
    console.log('buyGCNbuyGCNbuyGCNbuyGCN', options);

    return axios(options)
        .then(users => {
            console.log('buyGCNbuyGCNbuyGCNbuyGCN', users.data);
            let bucketObj = {
                buyGCN: users.data
            }
            return bucketObj;
        }).catch(error => {
            console.log("errorerrorerrorerrorerrorerrorerrorerror1111", error);
        })
}


function addContractAddress(data) {




    const options = {
        url: CONST.BACKEND_URL + `/addContractAddress`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": authHeader().Authorization
        },
        data: JSON.stringify(data)
    };


    console.log("@@@@@@@@@@@", options);
    return axios(options)
        .then(response => {
            console.log('addContractAddress IN SERVICE', response);
            let bucketObj = {
                addResponce: response.data
            }
            return bucketObj;
        }).catch(c => {
            console.log("shdjsghdfjsfh", c);
        })

}


function updateProfile(formData) {
    let { users } = store.getState()
    const options = {
        url: CONST.BACKEND_URL + `/updateProfile`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": authHeader().Authorization
        },
        data: JSON.stringify(formData)
    };
    return axios(options)
        .then(response => {
            // console.log('UPDATE PROFILE IN SERVICE', response.data);
            let bucketObj = {
                updateProfile: response.data
            }
            return bucketObj;
        });
}








function createWallet(data) {
    let { users } = store.getState()
    const options = {
        url: CONST.BACKEND_URL + `/createAddress`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": authHeader().Authorization
        },
        data: JSON.stringify(data)
    };
    return axios(options)
        .then(response => {
            // console.log('createWallet----------->', response.data);
            let bucketObj = {
                createWalletData: response.data
            }
            return bucketObj;
        });
}
function getAddressList(data) {
    let { users } = store.getState()
    const options = {
        url: CONST.BACKEND_URL + `/getAddressList`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": authHeader().Authorization
        },
        // data: JSON.stringify(data)
    };
    return axios(options)
        .then(response => {
            console.log('getAddress----------->', response.data);
            let bucketObj = {
                getAddressData: response.data.data.data
            }
            return bucketObj;
        });
}
function getProfile() {
    let { users } = store.getState()
    const options = {
        url: CONST.BACKEND_URL + `/getProfile`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": authHeader().Authorization
        },
        // data: JSON.stringify(data)
    };
    return axios(options)
        .then(response => {
            // // console.log('GET PROFILE IN SERVICE', response.data);
            let bucketObj = {
                getProfile: response.data
            }
            return bucketObj;
        });
}

// function getWalletList(data) {
//     console.log('data_________________________________', data);
//     let { users } = store.getState()
//     console.log('===== SELECTED WALLET ADDRESS =======>', users);
//     const options = {
//         url: CONST.BACKEND_URL + `/getWalletListByAddress`,
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//             "Authorization": authHeader().Authorization
//         },
//         data: JSON.stringify(data)
//     };
//     console.log("getWalletListByAddress", options);


//     return axios(options)
//         .then(response => {
//             let originalArray = response.data.data



//             console.log("uniqueData", originalArray);

//             let bucketObj = {



//                 getWalletList: originalArray
//             }
//             return bucketObj;
//         }).catch(error => {
//             console.log('EERRREEERRRRRR  =>', error);
//         })


// }
function register(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/register`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                userinfo: user
            }
            if (user.data) {
                window.sessionStorage.setItem('user', JSON.stringify(user.data));
            }

            return userObj;
        });
}

function logout() {
    window.sessionStorage.removeItem('user');
    history.push(`#/login`);

}



function onerrorlogout() {
    window.sessionStorage.removeItem('user');
    history.push(`#/login`);
    window.location.reload();
}





function login(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                userinfo: user
            }
            if (user.data) {
                window.sessionStorage.setItem('user', JSON.stringify(user.data));
            }

            return userObj;
        });

}

function getCountryCodeList(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
    }
    return fetch(CONST.BACKEND_URL + `/getCountryCodeList`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                getCountryCodeList: user
            }
            return userObj;
        });
}






function getWalletList(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    console.log('requestOptions____________________requestOptions', requestOptions);
    return fetch(CONST.BACKEND_URL + `/getWalletListByAddress`, requestOptions)
        .then(handleResponse)
        .then(user => {
            console.log('user___________________________', user);
            let userObj = {
                getWalletList: user.data
            }
            // if (user.data) {
            //     window.sessionStorage.setItem('user', JSON.stringify(user.data));
            // }

            return userObj;
        });
}

function updatePasswordValidateOtp(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updatePasswordValidateOtp`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                userinfo: user
            }
            // if (user.data) {
            //     window.sessionStorage.setItem('user', JSON.stringify(user.data));
            // }

            return userObj;
        });
}



function validateLoginOtp(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/validateLoginOtp`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                userinfo: user
            }
            if (user.data) {
                window.sessionStorage.setItem('user', JSON.stringify(user.data));
            }

            return userObj;
        });
}



function loginValidateOtp(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/loginValidateOtp`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                userinfo: user
            }
            if (user.data) {
                window.sessionStorage.setItem('user', JSON.stringify(user.data));
            }

            return userObj;
        });
}





function registerValidateOtp(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/validateRegisterOtp`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                userinfo: user
            }
            if (user.data) {
                window.sessionStorage.setItem('user', JSON.stringify(user.data));
            }

            return userObj;
        });
}

function forgotPassword(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/forgotPassword`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                userinfo: user
            }
            return userObj;
        });
}








function forgotUpdatePassword(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/forgotUpdatePassword`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                userinfo: user
            }
            return userObj;
        });
}

function getUserDetails(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getProfile`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getUserDetails: data.data
            }
            // console.log('I am service ghdfhdfghdfhhhhhhhhhhhhhhhhhhhhhhg::', userObj);
            return userObj;
        });
}


function updateUserInfo(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateUserInfo`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateUserInfo: data
            }
            return userObj;
        });
}





function getKYCDetails(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getKYCDetails`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getKYC: data.data
            }
            return userObj;
        });
}

function saveKycDocSelfie(data) {
    // console.log("Service____saveKycDocSelfie____data::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/saveKycDocSelfie`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                saveKycDocSelfieData: data.data
            }
            return userObj;
        });
}

function saveKycDocPassport(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/saveKycDocPassport`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                saveKycDocPassportData: data.data
            }
            return userObj;
        });
}

function saveKycDocPan(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/saveKycDocPan`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                saveKycDocPanData: data.data
            }
            return userObj;
        });
}

function saveKycDocAadhar(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/saveKycDocAadhar`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                saveKycDocAadharData: data.data
            }
            return userObj;
        });
}

function saveKycDoc(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/saveKycDoc`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                saveKycDocData: data.data
            }
            return userObj;
        });
}


function saveUserInfo(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/saveUserPersonalAddr`, requestOptions)
        .then(handleResponse)
        .then(data => {

            console.log("datadadadada", data);


            let userObj = {
                saveUserInfoData: data.data
            }
            return userObj;
        })
}

function createKYC() {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        // body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createKYC`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                kycData: data.data
            }
            return userObj;
        });
}

function getTx(data) {
    // console.log("I am in service getTx________data::", data);

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        // body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getTx`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                txlistData: data.data
            }
            // console.log("I am in service getTx::", userObj);
            return userObj;
        });
}


function updatePassword(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/changePassword`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                userinfo: data
            }

            return userObj;
        });
}

function uploadImagePassport(filedata) {

    let header = new Headers({
        "Authorization": authHeader().Authorization
    });
    var data = new FormData();
    data.append('image', filedata);

    const requestOptions = {
        method: "POST",
        headers: header,
        body: data
    }
    return fetch(CONST.BACKEND_URL + `/uploadImage`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                passportFilesDetails: res.data
            }
            return userObj;
        });
}
function uploadImageAdhaar(filedata) {

    let header = new Headers({
        "Authorization": authHeader().Authorization
    });
    var data = new FormData();
    data.append('image', filedata);

    const requestOptions = {
        method: "POST",
        headers: header,
        body: data
    }
    return fetch(CONST.BACKEND_URL + `/uploadImage`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                adhaarFilesDetails: res.data
            }
            return userObj;
        });
}




function uploadImagePan(filedata) {

    let header = new Headers({
        "Authorization": authHeader().Authorization
    });
    var data = new FormData();
    data.append('image', filedata);

    const requestOptions = {
        method: "POST",
        headers: header,
        body: data
    }
    return fetch(CONST.BACKEND_URL + `/uploadImage`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                panFilesDetails: res.data
            }
            return userObj;
        });
}
function uploadImageSelfie(filedata) {

    let header = new Headers({
        "Authorization": authHeader().Authorization
    });
    var data = new FormData();
    data.append('image', filedata);

    const requestOptions = {
        method: "POST",
        headers: header,
        body: data
    }
    return fetch(CONST.BACKEND_URL + `/uploadImage`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                selfieFilesDetails: res.data
            }
            // console.log("service uploadImageSelfie:", userObj);
            return userObj;
        });
}
function uploadImage(filedata) {

    let header = new Headers({
        "Authorization": authHeader().Authorization
    });
    var data = new FormData();
    data.append('image', filedata);

    const requestOptions = {
        method: "POST",
        headers: header,
        body: data
    }
    return fetch(CONST.BACKEND_URL + `/uploadImage`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                filesDetails: res.data
            }
            console.log("image111111111111111", userObj);
            return userObj;
        });
}




function handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        // console.log("handleResponse_handleResponse_handleResponse:::", data);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            if (data.code === 3) {
                onerrorlogout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}








// New Api



