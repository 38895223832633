import React, { Component } from "react";
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import Timer from 'otp-timer'
import { BsGlobe } from "react-icons/bs";
import { AiOutlineMail } from 'react-icons/ai';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import NetworkDetector from '../../components/Hoc/NetworkDetector';

class Login extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(userActions.logout());
    this.loginSubmit = this.loginSubmit.bind(this);
    // this.otpSubmit = this.otpSubmit.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.state = {
      fieldslogin: {},
      errorslogin: {},
      otpSentLogin: false,
      showPassword: false,
      rememberMeChecked: false
    }
    this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.otpSentLogin) {
      return {
        ...nextProps,
        otpSentLogin: nextProps.otpSentLogin ? nextProps.otpSentLogin : false
      }
    } else {
      return {
        ...nextProps
      }
    }
  }



  inputChange(e) {
    if (e) {
      e.preventDefault();
      const { name, value } = e.target;
      this.setState({ [name]: value });
      let fieldslogin = this.state.fieldslogin;
      let errorslogin = this.state.errorslogin;
      fieldslogin[name] = value;
      errorslogin[name] = "";
      this.setState({ fieldslogin, errorslogin });
    }
  }



  loginSubmit(e) {
    if (e) {
      e.preventDefault();
    }
    if (this.handleValidationLogin()) {
      let { userName, password } = this.state;
      this.props.dispatch(
        userActions.login({ userName: userName, password: password }, this.props)
      );
    }
  }

  resendSubmit = (e) => {
    // e.preventDefault();
    if (this.handleValidationLogin()) {
      let { userName, password, } = this.state;
      this.props.dispatch(userActions.login({ userName: userName, password: password, }, this.props));
    }

  }

  resetForm = (e) => {
    e.preventDefault();
    this.setState({
      fieldslogin: {},
      errorslogin: {},
    })
    this.hideErrorMessage();
  }


  handleValidationLogin = () => {
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = {};
    let formIsValid = true;

    //userName
    if (!fieldslogin["userName"]) {
      formIsValid = false;
      errorslogin["userName"] = "Please enter a valid username.";
    }

    //Password
    if (!fieldslogin["password"]) {
      formIsValid = false;
      errorslogin["password"] = "The password field is required. ";
    }
    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  }

  componentDidMount() {
    const rememberMeChecked = localStorage.getItem('rememberMeChecked') === 'true';
    this.setState({ rememberMeChecked });
  }

  onClickMenu = (url) => {
    // console.log("url:", url);
    this.props.history.push(url)
  }




  togglePasswordVisibility() {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  }

  handleCheckboxChange = (event) => {
    const { checked } = event.target;
    this.setState({ rememberMeChecked: checked });
    // Save preference to local storage
    localStorage.setItem('rememberMeChecked', checked);
  };

  render() {
    let { loading } = this.props;

    return (
      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <>

          {/* <div className="overflow-auto lg:overflow-hidden">
            <div className="bg-gray-100 ">
              <div className="flex flex-col justify-center h-screen">
                <div className="flex h-screen overflow-auto lg:overflow-hidden" >
                  <div className="flex flex-col flex-1 w-full h-screen overflow-hidden overflow-y-auto">

                    <div className="flex w-full h-screen lg:overflow-hidden overflow-y-auto items-center bg-[#191341]">

                      <div className="items-center justify-center hidden w-full h-screen bg-[1D173F] lg:flex ">
                        <div className="w-full">
                          <div className="flex justify-center pt-2">
                            <div className="space-y-2">
                              <div className="flex items-center justify-center w-full">
                                <img className="w-24 h-24 lg:w-28 lg:h-28 2xl:w-36 2xl:h-36 lg:ml-10" src="/imge/Images/playstore.png" alt="gcn.png" />
                              </div>
                              <h1 className="text-4xl font-extrabold text-white lg:text-5xl uppercase tracking-wider text-center">Welcome To
                                <br />
                              </h1>

                              <div className="flex items-center justify-center pt-5 ">
                                <div className="">
                                  <img className="w-20 h-20" src="/imge/Images/playstore.png" alt="gcn.png" />
                                </div>
                                <div>
                                  <span className=" text-[#977b4b] text-2xl font-extrabold  lg:text-3xl uppercase text-center">GLOBAL CITIZEN
                                  </span>
                                  <br />
                                  <span className=" text-[#977b4b] text-2xl font-extrabold  lg:text-3xl uppercase text-center"> ID WALLET
                                  </span>
                                </div>

                              </div>
                            </div>
                          </div>
                          <div className="bg-[#E05D2D] h-0.5 w-74 ml-8 mr-10 lg:mt-14 mt-6"></div>
                          <div className="hidden px-4 py-5 space-y-3 lg:flex lg:flex-col lg:mt-5 lg:py-8">
                            <h1 className="text-2xl font-bold text-center text-white lg:text-2xl">Global Citizen identity Wallet</h1>
                            <h1 className="text-lg text-center text-gray-400">Copyright © 2023 Global Citizen identity Wallet - All Rights Reserved.</h1>
                            <h1 className="flex items-center justify-center text-sm text-center text-gray-400 lg:text-base">

                            </h1>
                          </div>
                        </div>
                      </div>


                      <div className="flex items-center overflow-auto justify-center w-full h-screen space-y-3 bg_page ">
                        <div className="w-full py-5">
                          <div className="flex lg:hidden justify-center pt-5 ">
                            <div className="space-y-2 ">
                              <div className="flex items-center justify-center w-full">
                                <img className="w-24 h-24 lg:w-28 lg:h-28 2xl:w-36 2xl:h-36 lg:ml-10" src="/imge/Images/playstore.png" alt="gcn.png" />
                              </div>
                              <h1 className="text-3xl font-extrabold text-white lg:text-5xl uppercase tracking-wider text-center">Welcome To
                                <br />
                              </h1>

                              <div className="flex items-center justify-center pt-5 ">
                                <div className="">
                                  <img className="w-20 h-20" src="/imge/Images/playstore.png" alt="gcn.png" />
                                </div>
                                <div>
                                  <span className=" text-[#977b4b] text-2xl font-extrabold  lg:text-3xl uppercase text-center">GLOBAL CITIZEN
                                  </span>
                                  <br />
                                  <span className=" text-[#977b4b] text-2xl font-extrabold  lg:text-3xl uppercase text-center"> ID WALLET
                                  </span>
                                </div>

                              </div>
                            </div>
                          </div>

                          <div className="w-full py-4">
                            <h1 className="lg:sign-text text-3xl text-white text-center font-bold">Log in</h1>
                          </div>

                          <div className="flex-col w-5/6 mx-auto space-y-4 md:w-3/6 lg:mt-10">

                            <div className="">
                              <div className="">
                                <label className="text-sm font-medium text-white">Email Address</label>
                              </div>
                              <input disabled={this.state.otpSentLogin ? true : false} className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base font-normal text-black placeholder-white placeholder:text-[#c1bfe9]  ${!this.state.errorslogin["userName"] ? "'border-opacity-20 border-opacity-100 border placeholder-white font-base " : "border-opacity-100 border-none ring-2 ring-red-300"}`}
                                id="userName" name="userName" placeholder="example@gmail.com" type="userName" onChange={this.inputChange}
                                onKeyPress={(e) => {
                                  if (e.key === "Enter") {
                                    this.loginSubmit();
                                  }
                                }}
                              />
                              {this.state.errorslogin["userName"] ?
                                <div className="mt-1 font-serif text-red-500 invalid-feedback">
                                  {this.state.errorslogin["userName"]}
                                </div>
                                : null}
                            </div>

                            <div className="mt-2">
                              <div className="">
                                <label className="text-sm font-medium text-white">Password</label>
                              </div>
                              <input disabled={this.state.otpSentLogin ? true : false} className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base font-normal text-black placeholder-white placeholder:text-[#c1bfe9]  ${!this.state.errorslogin["password"] ? "'border-opacity-20 border-opacity-100 border placeholder-white font-base " : "border-opacity-100 border-none ring-2 ring-red-300"}`}
                                id="password" name="password" placeholder="Password" type="password" onChange={this.inputChange}
                                onKeyPress={(e) => {
                                  if (e.key === "Enter") {
                                    this.loginSubmit();
                                  }
                                }}
                              />
                              {this.state.errorslogin["password"] ?
                                <div className="mt-1 font-serif text-red-500 invalid-feedback">
                                  {this.state.errorslogin["password"]}
                                </div>
                                : null}
                            </div>

                            <div className="flex items-center justify-between px-2 mt-2">
                              <div className="flex items-center cursor-pointer">
                                <input id="remember_me" name="remember_me" type="checkbox"
                                  className="h-4 w-4 cursor-pointer text-[#c8c5d4] focus:ring-[#9c8fe6] border-gray-300 rounded" />
                                <label htmlFor="remember_me" className="block ml-2 text-sm text-[#c8c5d4]">
                                  Remember me
                                </label>
                              </div>
                              <div className="text-sm cursor-pointer" onClick={() => this.onClickMenu('forgot')}>
                                <h2 className="font-medium text-[#CB9731] hover:underline">Forgot your password?</h2>
                              </div>
                            </div>

                            {this.state.otpSentLogin ? (
                              <div className="flex-col lg:pt-2 lg:mt-10">
                                <div className="">
                                  <label className="text-sm font-medium text-white">Access Code</label>
                                </div>
                                <input
                                  className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base font-normal text-black placeholder-white placeholder:text-[#A09DC5]  ${!this.state.errorslogin["OTP"] ? "'border-opacity-20 border-opacity-100 border placeholder-white font-base " : "border-opacity-100 border-none ring-2 ring-red-300"
                                    }`}
                                  name="OTP"
                                  value={this.state.fieldslogin["OTP"] ? this.state.fieldslogin["OTP"] : ''}
                                  onChange={this.inputChange}
                                  placeholder=""
                                  type="text"
                                  onKeyPress={this.handleOTPKeyPress}
                                />
                                {this.state.errorslogin["OTP"] ? (
                                  <div className="mt-1 font-serif text-red-500 invalid-feedback">
                                    {this.state.errorslogin["OTP"]}
                                  </div>
                                ) : null}
                              </div>
                            ) : null}
                          </div>

                          {this.state.otpSentLogin ?
                            <div className="flex items-center justify-between w-5/6 mx-auto mr-auto md:w-3/6 lg:mt-5 ">
                              <div className="flex items-center py-2 ml-2 text-[#0E1F51] lg:ml-0">
                                <Timer textColor={"#0E1F51"} seconds={1} minutes={2} ButtonText="Resend Otp" resend={this.resendSubmit} />
                              </div>
                            </div> : null}

                          {this.state.otpSentLogin ?
                            <div className="mt-6 text-center">
                              <button className="w-5/6 py-2 text-white transition duration-150 ease-in-out rounded-full cursor-pointer all-btn-color lg:font-bold focus:outline-none focus:underline md:w-3/6 lg:py-3" type="button" onClick={(e) => this.otpSubmit(e)}>Access Code</button>
                            </div>
                            :
                            <div className="mt-6 text-center">
                              <button className="w-5/6 py-2 mx-auto text-white transition duration-150 ease-in-out rounded-full cursor-pointer all-btn-color lg:font-bold focus:outline-none focus:underline lg:py-3 md:w-3/6" type="submit" onClick={(e) => this.loginSubmit(e)}>Sign In</button>
                            </div>
                          }

                          <h1 className="text-[#A09DC5] text-center mt-3 lg:mt-8  text-[16px]">Don’t have an account yet? <span onClick={() => this.onClickMenu('/signup')} className="text-[#CB9731] cursor-pointer lg:text-sm text-[15px] hover:underline">Sign Up</span></h1>
                        </div>
                      </div>
                    </div>



                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <section className="flex items-center w-full">
            <div className="bg-gradient-to-t to-[#5B4DCA] from-[#2D2664] w-full relative lg:block hidden">
              <img className="absolute top-0 left-0" src="/img/Vector.png" alt="" />
              <div className="flex flex-col justify-center items-center h-screen">
                <div>
                  <img src="/img/logo.png" alt="" />
                </div>
                <div className="border-t border-[#FFFFFF4D] py-6">
                  <h1 className="text-white text-[60px] font-[700] text-center">Welcome Back</h1>
                  <p className="text-[#FFFFFFA1]/60 text-center text-[26px] font-[400]">To keep connect with us Please <br /> login with your personal information</p>
                </div>
              </div>
              <img className="absolute bottom-0 right-0" src="/img/Vector-1.png" alt="" />
            </div>
            <div className="bg-[#1C2745] w-full ">
              <div className="flex-col justify-center  flex w-5/6 mx-auto space-y-4 xl:w-3/6 h-screen">
                <div className="text-center">
                  <h1 className="text-[45px] font-[700] text-white">Log <span className="text-[#4F91F5]">in!</span></h1>
                  <p className="text-[22px] font-[400] text-[#FFFFFFA6]">Welcome back to Project-1</p>
                </div>
                <div className="space-y-2">
                  <label className="text-sm font-medium text-white">User name</label>
                  <div className="relative flex items-center">
                    <AiOutlineMail className="absolute right-3 text-gray-400" />
                    <input disabled={this.state.otpSentLogin} className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base font-normal  placeholder-white placeholder:text-[#c1bfe9] bg-transparent text-white ${!this.state.errorslogin["userName"] ? "'border-opacity-20 border-opacity-100 border placeholder-white font-base " : "border-opacity-100 border-none ring-2 ring-red-300"}`} id="userName" name="userName" placeholder="User Name" type="userName" onChange={this.inputChange} onKeyPress={(e) => { if (e.key === "Enter") { this.loginSubmit(); } }} />
                  </div>
                  {this.state.errorslogin["userName"] ? <div className="mt-1 font-serif text-red-500 invalid-feedback">{this.state.errorslogin["userName"]}</div> : null}
                </div>

                <div className="space-y-2">
                  <label className="text-sm font-medium text-white">Password</label>
                  <div className="relative flex items-center">
                    <input disabled={this.state.otpSentLogin} className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base font-normal text-white placeholder-white placeholder:text-[#c1bfe9] bg-transparent ${!this.state.errorslogin["password"] ? "'border-opacity-20 border-opacity-100 border placeholder-white font-base " : "border-opacity-100 border-none ring-2 ring-red-300"}`} id="password" name="password" placeholder="Password" type={this.state.showPassword ? "text" : "password"} onChange={this.inputChange} onKeyPress={(e) => { if (e.key === "Enter") { this.loginSubmit(); } }} onCopy={(e) => { e.preventDefault(); alert("Copying password is not allowed."); }} />
                    <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                      {this.state.showPassword ? <FaEyeSlash className="text-gray-400 cursor-pointer" onClick={this.togglePasswordVisibility} /> : <FaEye className="text-gray-400 cursor-pointer" onClick={this.togglePasswordVisibility} />}
                    </div>
                  </div>
                  {this.state.errorslogin["password"] ? <div className="mt-1 font-serif text-red-500 invalid-feedback">{this.state.errorslogin["password"]}</div> : null}
                </div>

                <div className="flex items-center justify-between px-2 mt-2">
                  {/* <div className="flex items-center cursor-pointer">
                    <input id="remember_me" name="remember_me" type="checkbox"
                      className="h-4 w-4 cursor-pointer text-[#c8c5d4] focus:ring-[#9c8fe6] border-gray-300 rounded" />
                    <label htmlFor="remember_me" className="block ml-2 text-sm text-[#c8c5d4]">
                      Keep me Signed In
                    </label>
                  </div> */}

                  <div className="flex items-center cursor-pointer">
                    <input
                      id="remember_me"
                      name="remember_me"
                      type="checkbox"
                      className="h-4 w-4 cursor-pointer text-[#c8c5d4] focus:ring-[#9c8fe6] border-gray-300 rounded"
                      checked={this.state.rememberMeChecked}
                      onChange={this.handleCheckboxChange}
                    />
                    <label htmlFor="remember_me" className="block ml-2 text-sm text-[#c8c5d4]">
                      Keep me Signed In
                    </label>
                  </div>
                  <div className="text-sm cursor-pointer" onClick={() => this.onClickMenu('forgot')}>
                    <h2 className="font-medium text-[#FFD600] hover:underline">Forgot password?</h2>
                  </div>
                </div>

                <div className="">
                  <button className="w-full py-3 mx-auto text-white transition duration-150 ease-in-out rounded-[8px] cursor-pointer all-btn-color lg:font-bold focus:outline-none focus:underline" type="submit" onClick={(e) => this.loginSubmit(e)}>Sign In</button>
                </div>
                <div className="py-6">
                  <p className="text-center text-[18px] font-[400] text-[#A09DC5]">Don’t have an Account ? <span onClick={() => this.onClickMenu('/signup')} className="text-[#508EF3] cursor-pointer">Sign Up</span></p>
                </div>
              </div>

            </div>
          </section>
        </>

      </>
    )
  }
}
function mapStateToProps(state) {
  const { loggingIn, user, otpSentLogin, registeruser, loading } = state.authentication;
  const { users, authentication } = state;
  return {
    loggingIn,
    otpSentLogin,
    user,
    users,
    registeruser,
    loading,
    authentication
  };
}
export default connect(mapStateToProps)(Login);