import React, { Component } from 'react';
import { connect } from 'react-redux';
import "jspdf-autotable";
import './styles.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { userActions } from '../../_actions';
import { authHeader } from '../../_helpers';



class Transaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      size: 10,
      keyWord: "",
      pageNo: 1,
    }
  }

  componentDidMount() {
    this.props.dispatch(userActions.getProfile());
  }





  render() {
    let { users } = this.props;
    let { getProfile } = users
    let userName = getProfile && getProfile.userName
    let tokenData = authHeader().Authorization
    let token = tokenData.replace(/^Bearer\s/, '');
    console.log('userName______________userName', userName, token);

    return (
      <>
        <section className='overflow-hidden overflow-y-auto lg:pb-0 pb-20'>
          <div className='border border-[#5B4DCA] rounded-[14px] p-6 space-y-4'>

            <div class="myIframe">
              <iframe src={`https://cryptoadminpayment.jamsara.com/#/payment/${userName}/${token}`} title="Payment getway" frameborder="0" className=''>
              {/* <iframe src={`http://localhost:3000/#/payment/${userName}/${token}`} title="Payment getway" frameborder="0" className=''> */}

              </iframe>
            </div>


            {/* </div> */}
          </div>
        </section>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}

export default connect(mapStateToProps)(Transaction);
