import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions, dashboardActions } from '.';
// import { history } from '../_helpers';
export const userActions = {
    getKYCDetails,
    getTx,
    createKYC,
    login,
    loginValidateOtp,
    registerValidateOtp,
    validateLoginOtp,
    forgotPassword,
    updatePasswordValidateOtp,
    register,
    logout,
    getUserDetails,
    forgotUpdatePassword,
    uploadImage,
    updatePassword,
    updateUserInfo,
    saveUserInfo,
    saveKycDoc,
    uploadImagePan,
    uploadImagePassport,
    uploadImageAdhaar,
    uploadImageSelfie,
    saveKycDocAadhar,
    saveKycDocPan,
    saveKycDocPassport,
    saveKycDocSelfie,
    getWalletList,
    getProfile,
    getAddressList,
    logout1,
    resetUser,
    Saveaddresss,
    createWallet,
    updateProfile,
    addContractAddress,
    buyGCN,
    sendCoin,
    sendCoinOtpVerification,
    getUserInfo,
    addUserTokenBalanceInWallet,
    getUserWalletToBuy,
    savedname,
    getCountryCodeList,
    clearCache
};

function savedname(data) {
    return dispatch => {
        dispatch(success(data));
    };
    function success(data) { return { type: userConstants.WALLET_NAME_SUCCESS, data } }
}
function clearCache() {
    return dispatch => {
        dispatch(success());
    };
    function success() { return { type: userConstants.CLEAR_CACHE_SUCCESS } }
}






function updateProfile(formData, props, setEditProfile) {
    return dispatch => {
        dispatch(request());
        userService.updateProfile(formData).then(
            users => {
                dispatch(success(users));
                // Alert.alert('Success!', 'Succeessfully Updated');
                dispatch(setEditProfile(true))
                setTimeout(() => {
                    // props.navigation.navigate('Dashboard')
                }, 1000);
            },
            error => {
                dispatch(failure(error));
            },
        );
    };
    function request() { return { type: userConstants.UPDATE_PROFILE_REQUEST }; }
    function success(users) { return { type: userConstants.UPDATE_PROFILE_SUCCESS, users }; }
    function failure(error) { return { type: userConstants.UPDATE_PROFILE_FAILURE, error }; }
}


function addContractAddress(data, props,) {
    return dispatch => {
        dispatch(request());
        userService.addContractAddress(data)
            .then(
                users => {
                    // console.log('------------->', users);
                    if (users.addResponce && users.addResponce.error) {
                        let message = users && users.addResponce && users.addResponce.message ? users.addResponce.message : ''
                        dispatch(alertActions.error(message));
                        // dispatch(alertActions.error(users && users.addResponce && users.addResponce.message ? users.addResponce.message : ''));
                        // dispatch(Alert.alert('Alert!', users.addResponce.message))
                        // dispatch(ActivityIndicator.alert('Alert!', users.addResponce.message))
                        // dispatch(failure(error));
                    } else {
                        let message = users && users.addResponce && users.addResponce.message ? users.addResponce.message : ''
                        dispatch(alertActions.success(message));
                        // dispatch(alertActions.error(users.addResponce.message));
                        dispatch(success(users));
                        setTimeout(() => {
                            // props.navigation.navigate('Dashboard');
                        }, 1000);
                    }
                },
            );
    };
    function request() { return { type: userConstants.ADD_CONTRACT_ADDRESS_REQUEST }; }
    function success(users) { return { type: userConstants.ADD_CONTRACT_ADDRESS_SUCCESS, users }; }
    function failure(error) { return { type: userConstants.ADD_CONTRACT_ADDRESS_FAILURE, error }; }
}





function buyGCN(data, props) {
    return dispatch => {
        dispatch(request());
        userService.buyGCN(data)
            .then(
                users => {



                    if (users && users.buyGCN && users.buyGCN.error === false) {
                        dispatch(userActions.getProfile())
                        dispatch(success(users));
                        dispatch(alertActions.success(users && users.buyGCN && users.buyGCN.message))
                        props.history.push(`/app/dashboard`)


                    } else {
                        dispatch(alertActions.error(users && users.buyGCN && users.buyGCN.message))
                    }


                },
                error => {
                    dispatch(failure(error));
                },
            );
    };
    function request() { return { type: userConstants.ADD_REQUEST } }
    function success(users) { return { type: userConstants.ADD_SUCCESS, users } }
    function failure(error) { return { type: userConstants.ADD_FAILURE, error } }
}

function sendCoin(data) {
    return dispatch => {
        dispatch(request());
        userService.sendCoin(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(alertActions.success('OTP send successfully.'));

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                },
            );
    };
    function request() { return { type: userConstants.SEND_COIN_REQUEST }; }
    function success(users) { return { type: userConstants.SEND_COIN_SUCCESS, users }; }
    function failure(error) { return { type: userConstants.SEND_COIN_FAILURE, error }; }
}

function sendCoinOtpVerification(data, props) {
    return dispatch => {
        dispatch(request());
        userService.sendCoinOtpVerification(data)
            .then(
                users => {
                    console.log('users________________________________users', users);
                    dispatch(success(users));
                    dispatch(alertActions.success('Successfully send.'));
                    dispatch(this.clearCache());
                    props.history.push('/app/dashboard')

                },
                error => {

                    if (error && error.otp) {
                        dispatch(failure(error));
                        dispatch(alertActions.error(error.otp));
                    } else {
                        dispatch(failure(error));
                        dispatch(alertActions.error(error));
                    }
                },
            );
    };
    function request() { return { type: userConstants.SEND_COIN_OTP_VERIFICATION_REQUEST }; }
    function success(users) { return { type: userConstants.SEND_COIN_OTP_VERIFICATION_SUCCESS, users }; }
    function failure(error) { return { type: userConstants.SEND_COIN_OTP_VERIFICATION_FAILURE, error }; }
}



function getCountryCodeList() {
    return dispatch => {
        dispatch(request());
        userService.getCountryCodeList()
            .then(
                users => {
                    console.log('users________________________________users', users);
                    dispatch(success(users));

                },
                error => {
                    dispatch(failure(error));
                },
            );
    };
    function request() { return { type: userConstants.GET_COUNTRY_CODE_LIST_REQUEST } }
    function success(users) { return { type: userConstants.GET_COUNTRY_CODE_LIST_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_COUNTRY_CODE_LIST_FAILURE, error } }
}

function getUserWalletToBuy(data) {
    return dispatch => {
        dispatch(request());
        userService.getUserWalletToBuy(data)
            .then(
                users => {
                    console.log('users________________________________users', users);
                    dispatch(success(users));

                },
                error => {
                    dispatch(failure(error));
                },
            );
    };
    function request() { return { type: userConstants.WALLETT_REQUEST } }
    function success(users) { return { type: userConstants.WALLETT_SUCCESS, users } }
    function failure(error) { return { type: userConstants.WALLETT_FAILURE, error } }
}

function getUserInfo(data) {
    return dispatch => {
        dispatch(request());
        userService.getUserInfo(data)
            .then(
                users => {

                    dispatch(success(users));

                },
                error => {
                    dispatch(failure(error));
                },
            );
    };
    function request() { return { type: userConstants.GET_USER_INFO_REQUEST } }
    function success(users) { return { type: userConstants.GET_USER_INFO_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_USER_INFO_FAILURE, error } }
}










function createWallet(data, props) {
    return dispatch => {
        dispatch(request());
        userService.createWallet(data).then(
            users => {
                if (users.createWalletData && users.createWalletData.error) {
                    dispatch(alertActions.error(users.createWalletData.message));
                    // dispatch(failure(error));
                } else {
                    let message = users && users.createWalletData && users.createWalletData.messages ? users.createWalletData.message : 'Wallet created successfully'
                    dispatch(alertActions.success(message));
                    dispatch(userActions.getAddressList())
                    dispatch(success(users));
                }
            },
        );
    };
    function request() { return { type: userConstants.CREATE_WALLET_REQUEST }; }
    function success(users) { return { type: userConstants.CREATE_WALLET_SUCCESS, users }; }
    function failure(error) { return { type: userConstants.CREATE_WALLET_FAILURE, error }; }
}
function Saveaddresss(data) {
    return dispatch => {
        dispatch(success(data));
    };
    function success(data) { return { type: userConstants.WALLET_SUCCESS, data } }
}




function resetUser() {
    return dispatch => {
        dispatch(success());
    };
    function success() { return { type: userConstants.RESET_USER_REDUX } }
}
function logout1() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}
function getAddressList(data) {
    return dispatch => {
        dispatch(request());
        userService.getAddressList(data).then(
            users => {
                if (users.getAddressData && users.getAddressData.error) {
                    // // dispatch(failure(error));
                } else {
                    dispatch(success(users));
                }
            },
        );
    };
    function request() { return { type: userConstants.GET_ADDRESS_REQUEST }; }
    function success(users) { return { type: userConstants.GET_ADDRESS_SUCCESS, users }; }
    function failure(error) { return { type: userConstants.GET_ADDRESS_FAILURE, error }; }
}
function addUserTokenBalanceInWallet() {
    return dispatch => {
        userService.addUserTokenBalanceInWallet()

    };
    // function request() { return { type: userConstants.ADD_USER_TOKEN_BALANCE_IN_WALLET_REQUEST }; }
    // function success(users) { return { type: userConstants.ADD_USER_TOKEN_BALANCE_IN_WALLET_SUCCESS, users }; }
    // function failure(error) { return { type: userConstants.ADD_USER_TOKEN_BALANCE_IN_WALLET_FAILURE, error }; }
}
function getProfile(tempsData) {
    return dispatch => {
        dispatch(request());
        userService.getProfile().then(
            users => {
                console.log("getProfilegetProfilegetProfile", users);
                dispatch(success(users));
            },
            error => {
                dispatch(failure(error));
            },
        );
    };
    function request() { return { type: userConstants.GET_PROFILE_REQUEST }; }
    function success(users) { return { type: userConstants.GET_PROFILE_SUCCESS, users }; }
    function failure(error) { return { type: userConstants.GET_PROFILE_FAILURE, error }; }
}


function getWalletList(tempsData) {
    console.log('1111111111122222222222233333333333444444444444444455555555555566666789', tempsData);
    return dispatch => {
        dispatch(request());
        userService.getWalletList(tempsData)
            .then(
                users => {

                    console.log("usersusersusersusersusers", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(failure(error));
                },
            );
    };
    function request() { return { type: userConstants.GET_WALLET_LIST_REQUEST }; }
    function success(users) { return { type: userConstants.GET_WALLET_LIST_SUCCESS, users }; }
    function failure(error) { return { type: userConstants.GET_WALLET_LIST_FAILURE, error }; }
}

function updatePassword(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.updatePassword(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(alertActions.success(users.userinfo.message));
                    dispatch(this.getUserDetails());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: userConstants.UPDATE_PASSWORD_REQUEST } }
    function success(users) { return { type: userConstants.UPDATE_PASSWORD_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_PASSWORD_FAILURE, error } }
}



function login(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.login(data)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success(user.userinfo.message));
                    props.history.push(`/app/dashboard`)
                    // props.history.push(`/app/dashboard`)
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: userConstants.LOGIN_FIRST_REQUEST } }
    function success(user) { return { type: userConstants.LOGIN_FIRST_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FIRST_FAILURE, error } }
}

function updatePasswordValidateOtp(data, props) {
    // console.log("updatePasswordValidateOtp________ACTION::", data);
    return dispatch => {
        dispatch(request({ data }));
        userService.updatePasswordValidateOtp(data)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success(user.userinfo.message));
                    dispatch(this.getUserDetails());
                    // props.history.push(`/app/dashboard`)
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.UPDATE_PASSWORD_VALIDATE_OTP_REQUEST, user } }
    function success(user) { return { type: userConstants.UPDATE_PASSWORD_VALIDATE_OTP_SUCCESS, user } }
    function failure(error) { return { type: userConstants.UPDATE_PASSWORD_VALIDATE_OTP_FAILURE, error } }
}

function validateLoginOtp(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.validateLoginOtp(data)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success(user.userinfo.message));
                    props.history.push(`/app/dashboard`)
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.VALIDATE_LOGIN_OTP_REQUEST, user } }
    function success(user) { return { type: userConstants.VALIDATE_LOGIN_OTP_SUCCESS, user } }
    function failure(user) { return { type: userConstants.VALIDATE_LOGIN_OTP_FAILURE, user } }
}
function loginValidateOtp(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.loginValidateOtp(data)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success(user.userinfo.message));
                    props.history.push(`/app/dashboard`)
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}


function forgotPassword(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.forgotPassword(data)
            .then(
                user => {
                    console.log('user_______', user);
                    dispatch(success(user));

                    dispatch(alertActions.success(user.userinfo.message));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.FORGOT_FIRST_REQUEST, user } }
    function success(user) { return { type: userConstants.FORGOT_FIRST_SUCCESS, user } }
    function failure(error) { return { type: userConstants.FORGOT_FIRST_FAILURE, error } }
}
function register(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.register(data)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success(user.userinfo.message));
                    props.history.push(`/app/dashboard`)
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.REGISTER_FIRST_REQUEST, user } }
    function success(user) { return { type: userConstants.REGISTER_FIRST_SUCCESS, user } }
    function failure(error) { return { type: userConstants.REGISTER_FIRST_FAILURE, error } }
}

function registerValidateOtp(data, props) {

    console.log(data);

    return dispatch => {
        dispatch(request({ data }));
        userService.registerValidateOtp(data)
            .then(
                user => {

                    console.log("otpsumbit______", user);
                    dispatch(success(user));
                    dispatch(alertActions.success(user.userinfo.message));
                    props.history.push(`/app/dashboard`)
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: userConstants.LOGIN_REQUEST } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function logout() {
    return dispatch => {
        dispatch(dashboardActions.resetDashboard());
        dispatch(this.resetUser());
        dispatch(this.logout1());
    };
}

function forgotUpdatePassword(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.forgotUpdatePassword(data)
            .then(
                user => {
                    dispatch(success(user));

                    if (user.userinfo.message === "Invalid OTP.") {
                        dispatch(alertActions.error(user.userinfo.message));

                    } else {
                        dispatch(alertActions.success(user.userinfo.message));
                        props.history.push(`/login`)
                    }




                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.FORGOT_PASS_UPDATE_REQUEST, user } }
    function success(user) { return { type: userConstants.FORGOT_PASS_UPDATE_SUCCESS, user } }
    function failure(error) { return { type: userConstants.FORGOT_PASS_UPDATE_FAILURE, error } }
}







function getUserDetails() {

    return dispatch => {
        dispatch(request());
        userService.getUserDetails()
            .then(
                users => {
                    // console.log("ACTION__getUserDetails___getUserDetails::", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.USER_DETAILS_REQUEST } }
    function success(users) { return { type: userConstants.USER_DETAILS_SUCCESS, users } }
    function failure(error) { return { type: userConstants.USER_DETAILS_FAILURE, error } }
}




function updateUserInfo(data) {

    return dispatch => {
        dispatch(request());
        userService.updateUserInfo(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(alertActions.success(users.updateUserInfo.message));
                    dispatch(this.getUserDetails());
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.UPDATE_USER_DETAILS_REQUEST } }
    function success(users) { return { type: userConstants.UPDATE_USER_DETAILS_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_USER_DETAILS_FAILURE, error } }
}














function getKYCDetails() {
    return dispatch => {
        dispatch(request());
        userService.getKYCDetails()
            .then(
                users => {
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_KYC_REQUEST } }
    function success(users) { return { type: userConstants.GET_KYC_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_KYC_FAILURE, error } }
}

function saveUserInfo(data) {
    console.log('datadatadatadatadatadatadatadata', data);
    return dispatch => {
        dispatch(request());
        userService.saveUserInfo(data)
            .then(
                users => {
                    console.log('usersusersusersusersusers', users);
                    dispatch(success(users));
                    dispatch(alertActions.success("Success"));
                    dispatch(userActions.getUserDetails());
                    // dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.SAVE_USER_INFO_REQUEST } }
    function success(users) { return { type: userConstants.SAVE_USER_INFO_SUCCESS, users } }
    function failure(error) { return { type: userConstants.SAVE_USER_INFO_FAILURE, error } }
}




function saveKycDocAadhar(data) {

    return dispatch => {
        dispatch(request());
        userService.saveKycDocAadhar(data)
            .then(
                users => {
                    // dispatch(this.uploadImageClearAdhaar());

                    dispatch(success(users));
                    dispatch(alertActions.success("Success"));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.SAVE_KYC_DOC_AADHAR_REQUEST } }
    function success(users) { return { type: userConstants.SAVE_KYC_DOC_AADHAR_SUCCESS, users } }
    function failure(error) { return { type: userConstants.SAVE_KYC_DOC_AADHAR_FAILURE, error } }
}

function saveKycDocPassport(data) {

    return dispatch => {
        dispatch(request());
        userService.saveKycDocPassport(data)
            .then(
                users => {
                    // dispatch(this.uploadImageClearPassport());

                    dispatch(success(users));
                    dispatch(alertActions.success("Success"));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.SAVE_KYC_DOC_PASSPORT_REQUEST } }
    function success(users) { return { type: userConstants.SAVE_KYC_DOC_PASSPORT_SUCCESS, users } }
    function failure(error) { return { type: userConstants.SAVE_KYC_DOC_PASSPORT_FAILURE, error } }
}

function saveKycDocSelfie(data) {
    // console.log("Action____saveKycDocSelfie____data::", data);

    return dispatch => {
        dispatch(request());
        userService.saveKycDocSelfie(data)
            .then(
                users => {
                    // dispatch(this.uploadImageClearSelfie());

                    dispatch(success(users));
                    this.createKYC();
                    dispatch(alertActions.success("Success"));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.SAVE_KYC_DOC_SELFIE_REQUEST } }
    function success(users) { return { type: userConstants.SAVE_KYC_DOC_SELFIE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.SAVE_KYC_DOC_SELFIE_FAILURE, error } }
}

function saveKycDocPan(data) {

    return dispatch => {
        dispatch(request());
        userService.saveKycDocPan(data)
            .then(
                users => {
                    // dispatch(this.uploadImageClearPan());

                    dispatch(success(users));
                    // dispatch(alertActions.success("Success"));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.SAVE_KYC_DOC_PAN_REQUEST } }
    function success(users) { return { type: userConstants.SAVE_KYC_DOC_PAN_SUCCESS, users } }
    function failure(error) { return { type: userConstants.SAVE_KYC_DOC_PAN_FAILURE, error } }
}


function saveKycDoc(data) {

    return dispatch => {
        dispatch(request());
        userService.saveKycDoc(data)
            .then(
                users => {
                    // dispatch(this.getKYC());
                    // dispatch(this.uploadImageClear());
                    // dispatch(this.uploadImageClearSelfie());
                    dispatch(success(users));
                    dispatch(alertActions.success("Success"));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.SAVE_KYC_DOC_REQUEST } }
    function success(users) { return { type: userConstants.SAVE_KYC_DOC_SUCCESS, users } }
    function failure(error) { return { type: userConstants.SAVE_KYC_DOC_FAILURE, error } }
}

function createKYC() {

    return dispatch => {
        dispatch(request());
        userService.createKYC()
            .then(
                users => {
                    // dispatch(this.getKYC());
                    dispatch(this.uploadImageClear());
                    dispatch(this.uploadImageClearSelfie());
                    dispatch(success(users));
                    dispatch(alertActions.success("Success"));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.USER_KYC_CREATE_REQUEST } }
    function success(users) { return { type: userConstants.USER_KYC_CREATE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.USER_KYC_CREATE_FAILURE, error } }
}

function uploadImagePassport(data) {
    return dispatch => {
        userService.uploadImagePassport(data)
            .then(
                users => {
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(alertActions.error("File size should not more than 1 mb"));
                    dispatch(failure(error))
                }
            );
    };
    function success(users) { return { type: userConstants.FILE_UPLOAD_STATUS_PASSPORT_SUCCESS, users } }
    function failure(error) { return { type: userConstants.FILE_UPLOAD_STATUS_PASSPORT_FAILURE, error } }
}

function uploadImagePan(data) {
    return dispatch => {
        userService.uploadImagePan(data)
            .then(
                users => {
                    dispatch(success(users));
                },
                error => {
                    // dispatch(alertActions.error(error));
                    dispatch(alertActions.error("File size should not more than 1 mb"));
                    dispatch(failure(error))
                }
            );
    };
    function success(users) { return { type: userConstants.FILE_UPLOAD_STATUS_PAN_SUCCESS, users } }
    function failure(error) { return { type: userConstants.FILE_UPLOAD_STATUS_PAN_FAILURE, error } }
}

function uploadImageAdhaarBack(data) {
    return dispatch => {
        userService.uploadImage(data)
            .then(
                users => {
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error("File size should not more than 1 mb"));
                    dispatch(failure(error))
                }
            );
    };
    function success(users) { return { type: userConstants.FILE_UPLOAD_STATUS_ADHAAR_BACK_SUCCESS, users } }
    function failure(error) { return { type: userConstants.FILE_UPLOAD_STATUS_ADHAAR_BACK_FAILURE, error } }
}

function uploadImageAdhaar(data) {
    return dispatch => {
        userService.uploadImageAdhaar(data)
            .then(
                users => {
                    dispatch(success(users));
                },
                error => {
                    // dispatch(alertActions.error(error));
                    dispatch(alertActions.error("File size should not more than 1 mb"));
                    dispatch(failure(error))
                }
            );
    };
    function success(users) { return { type: userConstants.FILE_UPLOAD_STATUS_ADHAAR_SUCCESS, users } }
    function failure(error) { return { type: userConstants.FILE_UPLOAD_STATUS_ADHAAR_FAILURE, error } }
}

function uploadImage(data) {
    return dispatch => {
        userService.uploadImage(data)
            .then(
                users => {
                    console.log('usersusersusersusersusers', users);
                    dispatch(success(users));
                    dispatch(alertActions.success('Image upload successfully'))
                },
                error => {
                    // dispatch(alertActions.error(error));
                    dispatch(alertActions.error("File size should not more than 1 mb"));
                    dispatch(failure(error))
                }
            );
    };
    function success(users) { return { type: userConstants.FILE_UPLOAD_STATUS_SUCCESS, users } }
    function failure(error) { return { type: userConstants.FILE_UPLOAD_STATUS_FAILURE, error } }
}

function uploadImageSelfie(data) {
    return dispatch => {
        userService.uploadImage(data)
            .then(
                users => {
                    // console.log("Action1312 uploadImageSelfie:", users);

                    dispatch(success(users));
                },
                error => {
                    // dispatch(alertActions.error(error));
                    dispatch(alertActions.error("File size should not more than 1 mb"));
                    dispatch(failure(error))
                }
            );
    };
    function success(users) { return { type: userConstants.FILE_UPLOAD_SELFIE_STATUS_SUCCESS, users } }
    function failure(error) { return { type: userConstants.FILE_UPLOAD_SELFIE_STATUS_FAILURE, error } }
}








function getTx(data) {

    return dispatch => {
        dispatch(request());
        userService.getTx(data)

            .then(
                users => {
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.TX_LIST_REQUEST } }
    function success(users) { return { type: userConstants.TX_LIST_SUCCESS, users } }
    function failure(error) { return { type: userConstants.TX_LIST_FAILURE, error } }
}



































